import React from 'react';
import './index.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <span className="code">© 2019-2024 Jacob Pabian</span>
      </footer>
    )
  }
}

export default Footer;