import React from 'react';
import Markdown from 'react-markdown';
import { Fade } from 'react-awesome-reveal';
import './index.scss';
import Hyperlink from '../Hyperlink';

class AboutMe extends React.Component {
  render() {
    return (
      <section id="about">
        <Fade direction="up" duration={500} delay={100} triggerOnce>
        <h2><a href="/#about"><span className="code">0001</span>About Me</a></h2>
        <div className="content">
          <Markdown>
            Hi there. I'm Jacob, a full-stack software engineer from Hampshire, England.
          </Markdown>
          <Markdown components={{a: Hyperlink}}>
            I have recently worked with [University of Manchester](https://www.manchester.ac.uk/) organisations and societies, in efforts to provide new opportunities for existing students to stretch their knowledge and skills in Computer Science.
          </Markdown>
          <Markdown components={{a: Hyperlink}}>
            My other work relates to [FiveM](https://fivem.net/), a modification for [Grand Theft Auto V](https://www.rockstargames.com/gta-v), enabling multiplayer games on dedicated servers. I write and optimise scripts written in Lua and C#, ran by thousands of players on a daily basis.
          </Markdown>
          <Markdown>
            These scripts often include client-facing web applications, using SPA web frameworks. I also oversee database management and server administration, amongst maintaining cyber security, network policies and system procedures.
          </Markdown>
        </div>

        <Fade direction="right" duration={1000} triggerOnce>
          <aside>
            <p>Here's a summary of my experience with frameworks & languages:</p>
            <ul className="list">
              <li>Python</li>
              <li>Vue.js</li>
              <li>React/Next</li>
              <li>(My)SQL</li>
              <li>C#/.NET</li>
              <li>Java</li>
              <li>C++</li>
              <li>Node.js</li>
              <li>Lua</li>
              <li>TypeScript</li>
            </ul>
          </aside>
        </Fade>
        </Fade>
      </section>
    )
  }
}

export default AboutMe;