import React from 'react';
import Headroom from 'react-headroom';
import NavOptions from './data.js';
import classNames from 'classnames';
import eventBus from '../../helpers/EventBus';
import './index.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.navPanel = React.createRef();
    this.openNav = this.openNav.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onClick = this.onClick.bind(this);
    this.selected = this.selected.bind(this);
    this.state = {navOpen: false, disable: false};
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    eventBus.on("imageAction", (opened) => {
      this.setState((state) => {
        return {
          ...state,
          disable: opened
        }
      })
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);  
    eventBus.remove("imageOpened");
  }

  onResize(e) {
    if (!this.state.navOpen) return;

    let width = window.innerWidth;
    
    if (width > 600) {
      this.openNav(false);
    }
  }

  onClick(e) {
    let node = this.navPanel.current;
    
    if (!node.contains(e.target)) {
      this.openNav(false);
    }
  }

  selected() {
    let width = window.innerWidth;

    if (width <= 600 && this.state.navOpen) {
      this.openNav(false);
    }
  }

  getBinary(num) {
    return parseInt(num+1 , 10).toString(2).padStart(4, "0")
  }

  openNav(explicit) {
    let bool = (typeof(explicit) == 'boolean' && explicit) ? explicit : !this.state.navOpen;

    this.setState({navOpen: bool});
    if (bool) {
      document.body.classList.add('blur');
      window.addEventListener('click', this.onClick);
    } else {
      document.body.classList.remove('blur');
      window.removeEventListener('click', this.onClick);
    }
  }

  render() {
    return (
      <Headroom className={
        classNames({
          'force-visible': this.state.navOpen,
          'force-hidden': this.state.disable
        })
      }>
        <header>
          <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt='logo' width='40' height='40'></img>
          <div id="nav">
            <nav>
              <ol>
                {NavOptions.map((data, index) => {
                  return (
                    <li key={index} className="nav-item">
                      <a value={this.getBinary(index)} href={`/${data.container}`}>{data.label}</a>
                    </li>
                  )
                })}
              </ol>
            </nav>
          </div>
          <div id="nav-mobile" ref={this.navPanel}>
            <nav>
              <button onClick={() => this.openNav()}>
                <div className="hamburger-box--wrapper">
                  <div className={
                    classNames({
                      "hamburger-box": true,
                      "active": this.state.navOpen,
                    })
                  } />
                </div>
              </button>
              
              <aside 
                className={
                  classNames({
                    "visible": this.state.navOpen
                  })
                }
              >
                <nav>
                  <ol>
                    {NavOptions.map((data, index) => {
                      return (
                        <li key={index} className="nav-item">
                          <a value={this.getBinary(index)} href={`/${data.container}`} onClick={this.selected}>{data.label}</a>
                        </li>
                      )
                    })}
                  </ol>
                </nav>
              </aside>
            </nav>
          </div>
        </header>
      </Headroom>
    )
  }
}

export default Header;