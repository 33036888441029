import React, { useEffect, useState } from 'react';
import './index.scss';
import Socials from './socials.js';

const Welcome = () => {
  const [scrollY, setScrollY] = useState(0);
  const [scrollVisible, setScrollVisible] = useState(true);

  useEffect(() => {
    window.onscroll = (e) => {
      setScrollY(window.scrollY);
    }
  }, []);

  return (
    <section id="welcome">
      <div className="left">
        <span className="code">Hello world! I am</span>
        <h1>Jacob Pabian.</h1>
        
        <div className="content">
          <p> 
            A software engineer from Hampshire, England.<br/>
            I develop optimised, modern applications and front-end user interfaces for videogames. I also build webpages.
          </p>
        </div>


        <div id="contact">
          <div id="email">
            <a href="mailto:me@jacobpabian.com">
              <button>Email Me</button>
            </a>
          </div>

          <div id="mobile-socials">
            <Socials />
          </div>
        </div>
      </div>
      <div className="right">
        <Socials />
      </div>

      {
        scrollVisible ?
        <div id="scroll-container">
          <div id="scroll" style={{opacity: scrollY < 100 ? 1 : 0}} onTransitionEnd={() => (scrollY >= 100 && setScrollVisible(false))}>
          <a href="/#about">
            <span /><span /><span />
          </a>
          </div>
        </div> : null
      }
    </section>
  )
}

export default Welcome;